<template>
  <div class="input-container" :class="[paddingSize]">
    <input
      class="input"
      :value="value"
      :type="type"
      :placeholder="placeholder"
      :id="id"
      :name="name"
      :autocomplete="autocomplete"
      @keyup="updateValue($event.target.id, $event.target.value)"
    />
    <p v-if="message !== null" :class="[message.type]">{{ message.text }}</p>
  </div>
</template>

<script>
export default {
  name: "BaseInput",
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    autocomplete: {
      type: String,
      default: "off",
    },
    message: {
      type: Object,
      default: null,
    },
  },
  methods: {
    updateValue: function (id, value) {
      this.$emit("updatedValue", { id: id, value: value });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.input-container {
  width: 100%;
  box-sizing: border-box;

  .input {
    box-sizing: border-box;
    width: 100%;
    border-radius: $standard-radius;
    border: none;
    padding: $standard-padding;
    font-size: 1rem;
    resize: vertical;
    background-color: darken($lm-background-color, 4%);
  }

  p {
    font-size: 0.7rem;
    padding-left: $standard-padding;
    padding-right: $standard-padding;
  }

  p.error {
    color: darken($lm-error-text-color, 60%);
  }

  p.warning {
    color: darken($lm-warning-text-color, 60%);
  }
}
</style>
