<template>
  <div class="forgot-password-container">
    <BaseNotification
      v-for="(notification, index) in notifications"
      :key="index"
      :notification="notification"
    />
    <div class="forgot-password-body">
      <h1>Wachtwoord vergeten</h1>
      <p class="description">
        Vul je e-mailadres in van de account waar je het wachtwoord niet meer
        van weet. Je zal dan een email ontvangen met instructies om je
        wachtwoord opnieuw in te stellen.
      </p>
      <form>
        <div v-for="input in inputs" :key="input.index">
          <BaseInput
            :placeholder="input.placeholder"
            :name="input.name"
            :id="input.id"
            :type="input.type"
            :value="input.value"
            :index="input.index"
            :message="input.message"
            @updatedValue="updateValue"
          />
        </div>
      </form>
      <BaseButton
        :disabled="emailInputEmpty"
        @buttonPressed="sendRequestForgotPasswordMail(inputs[0].value)"
        >Verzend</BaseButton
      >
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import BaseInput from "@/components/BaseInput";
import BaseNotification from "@/components/BaseNotification";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ForgotPassword",
  components: {
    BaseButton,
    BaseInput,
    BaseNotification,
  },
  data() {
    return {
      inputs: [
        {
          placeholder: "email",
          name: "email",
          id: "email",
          type: "email",
          value: "",
          message: null,
        },
      ],
      mailSendSuccess: false,
    };
  },
  computed: {
    ...mapGetters({
      notifications: "notification/notifications",
    }),
    emailInputEmpty() {
      return this.inputs[0].value === "" ? true : false;
    },
  },
  methods: {
    ...mapActions({
      checkLoggedIn: "auth/checkLoggedIn",
      requestForgotPasswordMail: "auth/requestForgotPasswordMail",
    }),
    updateValue(params) {
      this.inputs.forEach((input) => {
        if (input.id === params.id) {
          input.value = params.value;
        }
      });
      this.validateInput();
    },
    async sendRequestForgotPasswordMail(email) {
      this.mailSendSuccess = this.requestForgotPasswordMail(email);
    },

    validateInput() {
      if (this.emailInputEmpty) {
        this.inputs[0].message = { text: "Verplicht veld", type: "error" };
      } else {
        this.inputs[0].message = null;
      }
    },
  },
  async mounted() {
    if ((await this.checkLoggedIn()) === true) {
      this.$router.push("/menuboekEditor");
    }
    this.validateInput();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.forgot-password-container {
  padding-top: 10vh;
  margin-left: 20vw;
  margin-right: 20vw;
  .forgot-password-body {
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    h1 {
      text-align: center;
      margin-bottom: calc(#{$standard-margin}* 2);
    }
    .description {
      margin-bottom: $standard-margin;
    }
    form {
      margin-bottom: $standard-margin;
    }
    button {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
