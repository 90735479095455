<template>
  <div class="notification-container">
    <div class="content" :class="[notification.type]">
      <div class="message">{{ notification.message }}</div>
      <div class="icon" @click="findAndRemoveNotification(notification.id)">
        <font-awesome-icon :icon="['fas', 'times']" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "BaseNotification",
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      findAndRemoveNotification: "notification/findAndRemoveNotification",
    }),
  },
  created() {
    setTimeout(() => {
      this.findAndRemoveNotification(this.notification.id);
    }, this.notification.duration * 1000);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.notification-container {
  margin-bottom: $standard-margin;
  .content {
    padding: $standard-padding;
    display: grid;
    grid-template-columns: auto 40px;
    border-radius: $standard-radius;

    .message {
    }
    .icon {
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      font-size: 1.3rem;
    }
  }

  .succes {
    background-color: $lm-succes-background-color;
    color: darken($lm-succes-text-color, 60%);
  }

  .warning {
    background-color: $lm-warning-background-color;
    color: darken($lm-warning-text-color, 60%);
  }

  .error {
    background-color: $lm-error-background-color;
    color: darken($lm-error-text-color, 60%);
  }
}
</style>
